*, p {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

body, html {
    line-height: 1;
    background-color: #000000;
    font-size: 14px;
}

.font-bold {
    font-weight: 700;
}

a {
    text-decoration: none;
}

.bg-color {
    background-color: #404040;
}
.padding5 {
    padding: 3rem !important;
}
.contain {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
    margin-bottom: 3rem !important;
}
.text-lheader {
    --bs-text-opacity: 1;
    color: white;
    margin-bottom: 1.5rem !important;
    font-size: 2.5rem;
}
.text-contain {
    font-size: 1.5rem !important;
    line-height: 1.25 !important;
    color:white;
}
.col-md {
    flex: 0 0 auto;
    width: 66.66666667%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
.bg-white {
    --bs-bg-opacity: 1;
    background-color: white !important;
    border-radius: 0.25rem !important;
/*    height: 400px;*/
}
.coltext-contain {
    flex: 0 0 auto;
    width: 33.33333333%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
.feature-content {
    background-image: url(../images/background-img.svg);
    background-repeat: repeat-x;
    padding: 3rem !important;
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}
.fcol-md {
    flex: 0 0 auto;
    width: 50%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
.ftext-white {
    font-weight: 700;
    font-size: 2.5rem !important;
    --bs-text-opacity: 1;
    color: white !important;
}
.fbg-white {
    border-radius: 0.25rem !important;
    --bs-bg-opacity: 1;
    background-color: white !important;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}
.fstext-contain {
    font-size: 2rem !important;
    margin-bottom: 1rem !important;
}
.flong-text {
    line-height: 1.25 !important;
    font-size: 1.25rem !important;
}
.fcol-md6 {
    flex: 0 0 auto;
    width: 25%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}