
*, p {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

body, html {
    line-height: 1;
    background-color: #292929;
    font-size: 14px;
}

.font-bold {
    font-weight: 700;
}

a {
    text-decoration: none;
}

.bg-color {
    background-color: #404040;
}

.cursor-pointer {
    cursor: pointer;
}

.navigation {
    position: fixed;
    width: 80px;
    height: 100vh;
    background: linear-gradient(180deg, rgba(79,11,67,1) 0%, rgba(26,26,26,1) 100%);
    border: 0px solid #000;
}

    .navigation.home {
        position: relative;
        height: auto;
        margin-top: 64px;
        /* height: calc(100vh - 64px);
    top: 64px; */
    }

.home-logo {
    background-color: #4F0B43;
    position: absolute;
    margin-left: 0px;
}

.navigation .nav-link {
    padding: 0.8rem 0.5rem;
}

    .navigation .nav-link span {
        display: block;
        font-size: 12px;
        line-height: 1.25rem;
        color: #fff;
    }

    .navigation .nav-link.active, .navigation .nav-link:hover {
        background-color: #434343;
    }

        .navigation .nav-link.active span {
            font-weight: 700;
        }

.nav-link .bi {
    color: #ffffff;
    position: absolute;
    right: 10px;
    top: 16px;
}

.nav-link.dropdown-btn.active .bi {
    transform: rotate(90deg);
}

.expand-nav {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 330px;
    background: #292929;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
    z-index: 9;
}

    .expand-nav .nav > li > a {
        border-bottom: 1px solid #575757;
    }

    .expand-nav .nav > li ul {
        border-bottom: 1px solid #575757;
    }

    .expand-nav .nav li .active span {
        font-weight: 700;
    }

.body-section {
    margin-left: 80px !important;
    width: calc(100% - 80px);
}

.top-header-section {
    background-color: #4F0B43;
}
.ourbanner-content {
    padding-top: 100px;
    padding-left:50px;
    width: 100%;
    height: 100%;
    color: #ffffff;
    
}
.labbanner-content {
    padding-top: 100px;
    padding-left: 50px;
    width: 100%;
    height: 100%;
    color: #ffffff;
    background: linear-gradient(180deg, rgba(79,11,67,1) 0%, rgba(26,26,26,1) 100%);
}

.banner-content {
    padding-top: 280px;
    color: #ffffff;
}

    .banner-content h1 {
        font-size: 80px;
        font-weight: 700;
        line-height: 73px;
        color: #ffffff;
    }

.cursole {
    background: linear-gradient(180deg, #4F0B43 -7.66%, #191919 100%);
    background-repeat: repeat-x;
}

.feature-content {
    background-image: url(../images/background-img.svg);
    background-repeat: no-repeat;
    background-size:cover;
    margin-left:20px;
}


.header-section {
    background-color: #292929;
}

    .header-section .search {
        width: 500px;
        background: #404040;
        border: 1px solid #595959;
        border-radius: 6px;
    }

        .header-section .search .dropdown-menu {
            top: 10px !important;
            left: -50px !important;
        }

            .header-section .search .dropdown-menu .dropdown-item {
                padding: 1rem !important;
            }

.form-control:focus {
    box-shadow: none;
}

.header-section .search .form-control {
    color: #ffffff;
}

/*Auto Complete*/
.autocomplete-items {
    position: fixed;
    background-color: #ffffff;
    width: 500px;
    max-height: 200px;
    overflow-y: auto;
}

    .autocomplete-items > div {
        padding: 10px;
    }

        .autocomplete-items > div:hover {
            background-color: #f1f2f3;
            cursor: pointer;
        }

.autocomplete-active {
    background-color: #f1f2f3;
}

.suggestion-section li a {
    background: #404040;
    border: 1px solid #404040;
    border-radius: 16px;
    font-size: 14px;
    padding: 10px 16px;
    text-decoration: none;
    color: #ffffff;
}

.suggestion-section .remove-suggestion {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #515151;
    color: #ffffff;
    font-size: 12px;
    display: inline-block;
}

.suggestion-section li a.active {
    background-color: #ffffff;
    color: #000000;
    font-weight: 700;
}

.dropdown-container {
    display: none;
    background-color: #262626;
    padding-left: 8px;
}

.form-control.filter {
    background: #404040;
    border: 1px solid #595959;
    border-radius: 3px;
    color: #ffffff;
    min-width: 100px;
}

.btn-white {
    background: #FFFFFF;
    border: 1px solid #595959;
    border-radius: 3px;
}

.video-section .video .bi {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
    cursor: pointer;
}

.btn-present {
    background: #404040;
    border: 1px solid #595959;
    border-radius: 3px;
}

.video-conatiner {
    height: calc(100vh - 275px);
}

    .video-conatiner video {
        max-width: 100%;
        max-height: 100%;
    }

.hover:hover {
    text-decoration: underline;
}

.textcolor {
    color: white;
    font-size: 13px;
    text-decoration: none;
}

    .textcolor a {
        color: white;
        font-size: 13px;
        text-decoration: none;
        text-align: center;
        text-decoration-color: #ffffff;
    }

.modal-body {
    background-color: #171717;
}

.view-video-details {
    font-size: 0.875rem;
}


.slick-track {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    margin-left: 0 !important;
    margin-right: 0 !important
}
.view-video-details > span {
    color: #848484;
}

.agenda-list {
    border: 1px solid #404040;
    list-style: none;
    min-height: 400px;
    max-height: 420px;
    overflow-y: auto;
}
.header-Style {
    margin-bottom: 10px;
    padding-left: 0px !important;
}

.agenda-list li {
    border-bottom: 1px solid #E1E1E1;
    padding: 1rem 1.25rem;
}

    .agenda-list li .form-check-input {
        width: 23px;
        height: 23px;
        border: 1px solid #CCCCCC;
        border-radius: 4px;
        background-color: transparent;
        background-image: none;
    }

        .agenda-list li .form-check-input:checked {
            background-color: #ffffff;
            border-color: #000000;
            /* background-image: url(../images/background-img.svg);*/
            background-size: auto;
            background-repeat: no-repeat;
        }

.btn-add {
    background: #404040;
    border: 1px solid #595959;
    border-radius: 4px;
    color: #ffffff;
}

    .btn-add:hover {
        color: #ffffff;
    }

.btn-close {
    width: auto;
    height: auto;
    opacity: 1 !important;
    background: transparent;
}

    .btn-close:focus, .btn-close:focus-visible {
        box-shadow: none;
    }

.modal-close {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 9;
}

.form-control.new-agenda {
    background: #404040;
    border: 1px solid #595959;
    border-radius: 6px;
    height: 40px;
    color: #ffffff;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height:100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.discovery-filter {
    margin-bottom: 10px;
    padding-left: 10px !important;
}
/* Modal Content */
.modal-contenr {
    width: 70%;
    background-color:black
}

.header-width-100 {
    width: 0% !important;
}
/*    float:left;
*/
/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

    .close:hover,
    .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }

.create-agenda-header {
    background-color: #171717;
    color: white !important;
    border-bottom: 1px solid #404040 !important;
}

.create-agenda-footer {
    background-color: #171717;
    color: white !important;
    border-top: 1px solid #404040 !important;
}

.modal-header button {
    color:white !important;
}

.modal-header .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: white !important;
    background-image: url('../images/icons/close.svg') !important;
    background-size:25px;
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
    font-weight:bold;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #d1d1d1;
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
    border: none !important;
}

.add-agenda-padding {
    padding-top: 10px;
}

.add-agenda-float {
    float: left;
}

.add-agenda-create {
    border-radius: 50%;
    color: black;
    background-color: white;
    height: 15px;
    width: 15px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.play-agenda-btn {
    min-width: 150px;
    max-width: 150px
}



@media (min-width: 320px) and (max-width: 480px) {
    .modal-mm {
        max-width: 280px;
    }

    .play-agenda-h {
        height: 200px
    }

    .media-contents-h {
        height: 100px;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .modal-mm {
        max-width: 400px;
    }

    .play-agenda-h {
        height: 250px
    }

    .media-contents-h {
        height: 150px;
    }

    .agenda-content-min-h255 {
        height: 255px
    }

    .agenda-content-min-h195 {
        height: 195px
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .modal-mm {
        max-width: 600px;
    }

    .play-agenda-h {
        height: 450px
    }

    .media-contents-h {
        height: 200px;
    }

    .agenda-content-min-h255 {
        height: 255px
    }

    .agenda-content-min-h195 {
        height: 195px
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .modal-mm {
        max-width: 800px;
    }

    .play-agenda-h {
        height: 450px
    }

    .media-contents-h {
        height: 200px;
    }

    .agenda-content-min-h255 {
        height: 255px
    }

    .agenda-content-min-h195 {
        height: 195px
    }
}

@media (min-width: 1200px) {
    .modal-mm {
        max-width: 1000px;
    }

    .play-agenda-h {
        height: 450px
    }

    .media-contents-h {
        height: 200px;
    }

    .agenda-content-min-h255 {
        height: 255px
    }

    .agenda-content-min-h195 {
        height: 195px;
        margin-top:20px !important;
    }
    .slick-prev,
    .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: -10% !important;
        display: block;
        width: 20px;
        height: 40px !important;
        padding: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
    }
}
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: -10% !important;
    display: block;
    width: 20px;
    height: 40px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.loginbtn {
    padding: 15px;
}
.slick-next {
    right: 2% !important;
}
.slick-prev {
    left: 94% !important;
}
.nxt-labs-tabs a {
    color: #000000;
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    text-align: center;
}

.nxt-labs-tabs img {
    width: 100%;
    /*height: 115px;*/
    margin: 0 auto;
}


.authentication {
    width: 100%;
    height: 92vh;
    padding: 20% 30% 20% 30%;
    text-align: center;
}

.playAgenda-modal-width {
    min-width: 94vw;
    min-height: 98vh;
    bottom:0;
    top:0
}
.confirm-box__content {
    z-index: 300;
    background-color: gainsboro;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    padding: 1em;
    border-radius: 5px;
    width: 300px;
    max-width: 429px;
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}
