*, p {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

body, html {
    line-height: 1;
    background-color: #000000;
    font-size: 14px;
}

.font-bold {
    font-weight: 700;
}
.ftext-white {
    font-size: 2rem !important;
    line-height: 1.25 !important;
    margin-bottom: 1rem !important;
    --bs-text-opacity: 1;
    color: white !important;
}
.fstext-white {
    --bs-text-opacity: 1;
    color: white !important;
    font-size: 1.25rem !important;
    line-height: 1.25 !important;
}
.fcol-md6 {
    flex: 0 0 auto;
    width: 25%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
.fcol-md3 {
    flex: 0 0 auto;
    width: 25%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
.whats-new {
    margin-top: 5rem !important;
    padding-bottom: 5rem !important;
}
.fs-inno {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    line-height: 1 !important;
    --bs-text-opacity: 1;
    color: white !important;
    font-size: 2rem !important;
    font-weight: 700;
}
.fsbase-text {
    --bs-text-opacity: 1;
    color: white !important;
    line-height: 1.5 !important;
    font-size: 1rem !important;
    margin-top: 0.5rem !important;
}
.text-more {
    font-weight: 700;
    --bs-text-opacity: 1;
    color: white !important;
    font-size: 1rem !important;
    margin-top: 3rem !important;
    display: block !important;
    text-decoration: none;
}
