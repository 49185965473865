
*, p {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

body, html {
    line-height: 1;
    background-color: #292929;
    font-size: 14px;
}

.font-bold {
    font-weight: 700;
}

a {
    text-decoration: none;
}

.bg-color {
    background-color: #404040;
}

.cursor-pointer {
    cursor: pointer;
}

.navigation {
    position: fixed;
    width: 80px;
    height: 100vh;
    background-color: #292929;
    border: 1px solid #000000;
}
    .navigation.home {
        position: relative;
        height: auto;
        margin-top: 64px;
        width: 80px;
        background-color: #292929;
        border: 1px solid #000000;
        /* height: calc(100vh - 64px);
    top: 64px; */
    }

.home-logo {
    background-color: #4F0B43;
    position: absolute;
    margin-left:79px;
    padding: 1rem !important;
}

.navigation .nav-link {
    padding: 0.8rem 0.5rem;
}

    .navigation .nav-link span {
        display: block;
        font-size: 12px;
        line-height: 1.25rem;
        color: #fff;
    }

    .navigation .nav-link.active, .navigation .nav-link:hover {
        background-color: #434343;
    }

        .navigation .nav-link.active span {
            font-weight: 700;
        }

.nav-link .bi {
    color: #ffffff;
    position: absolute;
    right: 10px;
    top: 16px;
}
.nav-item {
    text-align: center !important;
    margin-bottom: 0.25rem !important;
    width: 100% !important;
    list-style: none;
}
.nav-active {
    background-color: #434343;
    padding: 0.8rem 0.5rem;
    display: block !important;
    color: #ffffff;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
.nav-active, nav-item, .nav-link:hover {
    background-color: #434343;
    width: 100% !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
.nav-link.dropdown-btn.active .bi {
    transform: rotate(90deg);
}

.expand-nav {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 330px;
    background: #292929;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
    z-index: 9;
}

    .expand-nav .nav > li > a {
        border-bottom: 1px solid #575757;
    }

    .expand-nav .nav > li ul {
        border-bottom: 1px solid #575757;
    }

    .expand-nav .nav li .active span {
        font-weight: 700;
    }

.row-contain {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
    padding-bottom: 3rem !important;
}
.col-contain {
    flex: 0 0 auto;
    width: 63%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
.col-contain1 {
    flex: 0 0 auto;
    width: 37%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}


.top-header-section {
    background-color: #4F0B43;
    width: 100% !important;
}

.banner-content {
    padding-top: 280px;
    color: #ffffff;
    padding-left: 1rem !important;
}

    .banner-content h1 {
        font-size: 80px;
        font-weight: 700;
        line-height: 73px;
        color: #ffffff;
    }
.text-content {
    line-height: 1.25 !important;
    font-size: 1.5rem !important;
    margin-top: 1rem !important;
}

.cursole {
    background: linear-gradient(180deg,#4f0b43 10%,#191919);
    background-repeat: repeat-x;
}

.feature-content {
    background-image: url(../images/background-img.svg);
    background-repeat: repeat-x;
}


.header-section {
    background-color: #292929;
}

    .header-section .search {
        width: 500px;
        background: #404040;
        border: 1px solid #595959;
        border-radius: 6px;
    }

.form-control:focus {
    box-shadow: none;
}

.header-section .search .form-control {
    color: #ffffff;
}

/*Auto Complete*/
.autocomplete-items {
    position: fixed;
    background-color: #ffffff;
    width: 500px;
    max-height: 200px;
    overflow-y: auto;
}

    .autocomplete-items > div {
        padding: 10px;
    }

        .autocomplete-items > div:hover {
            background-color: #f1f2f3;
            cursor: pointer;
        }

.autocomplete-active {
    background-color: #f1f2f3;
}

.suggestion-section li a {
    background: #404040;
    border: 1px solid #404040;
    border-radius: 16px;
    font-size: 14px;
    padding: 10px 16px;
    text-decoration: none;
    color: #ffffff;
}

.dropdown-container {
    display: none;
    background-color: #262626;
    padding-left: 8px;
}

.form-control.filter {
    background: #404040;
    border: 1px solid #595959;
    border-radius: 3px;
    color: #ffffff;
    min-width: 100px;
}

.btn-white {
    background: #FFFFFF;
    border: 1px solid #595959;
    border-radius: 3px;
}

.video-section .video .bi {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
    cursor: pointer;
}

.btn-present {
    background: #404040;
    border: 1px solid #595959;
    border-radius: 3px;
}

.video-conatiner {
    height: calc(100vh - 275px);
}

.hover:hover {
    text-decoration: underline;
}
.overflow {
    display: flex !important;
    overflow: hidden !important;
}
.img-fluid {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
.img-fluidMb {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    margin-bottom: 1.5rem !important;
}
